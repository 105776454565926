export const calculateCartTotal = (cart, selectedQuantities, products) => {
  return cart.reduce((acc, item) => {
    let itemPrice = item.price;
    let acompanantesPrice = 0;

    if (Array.isArray(item.modifier_groups)) {
      item.modifier_groups.forEach((group) => {
        if (Array.isArray(group.app_mg_items)) {
          group.app_mg_items.forEach((acompanante) => {
            const product = products.find(
              (product) => product.app_item_id === acompanante.app_item_id
            );

            const selectedQuantity =
              selectedQuantities[
                `${item.app_item_id}-${acompanante.app_item_id}`
              ] || 0;
            acompanantesPrice += product.price * selectedQuantity;
          });
        }
      });
    }

    return acc + itemPrice * item.quantity + acompanantesPrice;
  }, 0);
};
