import styled from "styled-components";
import Swal from 'sweetalert2';




const REDIRECT_WHATSAPP = process.env.REACT_APP_REDIRECT_WHATSAPP_EXPIRE;





export const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1000",
  },
  content: {
    backgroundColor: "#f7f7f7",
    overflow: 'auto',
    padding: "40px",
    borderRadius: "20px",
    width: "60%",
    maxWidth: "600px",
    margin: "auto",
    border: "none",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
  },
};

export const StyledInput = styled.input`
  width: 15%;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #f8f8f8;
  s &:focus {
    border-color: #007bff;
    outline: none;
  }
`;




  // Estilos CSS para la barra de carga
  export const style_alert_whatsap = document.createElement('style');
style_alert_whatsap.innerHTML = `
  .progress-bar-container {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 20px;
  }
  .progress-bar {
    position: absolute;
    width: 0;
    height: 100%;
    background-color: #4caf50;
    animation: progressBarAnimation 2s infinite;
  }
  @keyframes progressBarAnimation {
    0% { width: 0; }
    100% { width: 100%; }
  }
`;
document.head.appendChild(style_alert_whatsap);

  




export const MessageAlertTokenExpire = () => {
  Swal.fire({
    icon: "info",
    title: "Tu sesión ha caducado. Por favor, vuelve a ingresar al menú.",
    html: `
      <p>Por favor, genera uno nuevo.</p>
      <button id="generate-menu-btn" class="swal2-confirm swal2-styled">Generar menú</button>
    `,
    showConfirmButton: false,
    allowOutsideClick: false,
    didRender: () => {
      document.getElementById('generate-menu-btn').addEventListener('click', () => {
       window.location.href = REDIRECT_WHATSAPP;
      });
    }
  });
};

// Opcional: Si quieres exportar estilos adicionales
const style = document.createElement('style');
style.innerHTML = `
  #generate-menu-btn {
    background-color: #3085d6;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  #generate-menu-btn:hover {
    background-color: #2874a6;
  }
`;
document.head.appendChild(style);
