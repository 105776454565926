import Swal from "sweetalert2";

const REDIRECT_WHATSAPP = process.env.REACT_APP_REDIRECT_WHATSAPP;
const API_URL_ITEMS = process.env.REACT_APP_ITEMS;
const BASIC_AUTH_TOKEN = process.env.REACT_APP_BASIC_AUTH_TOKEN;
const API_URL_CATEGORIES = process.env.REACT_APP_CATEGORIES;
const API_URL_MODIFIER_GROUPS = process.env.REACT_APP_MODIFIER_GROUPS;

const getOrigenZona = async (tokenFromUrl) => {
  try {
    const URL_BASE = process.env.REACT_APP_API_URL_DOMICILIO;
    const urlWithToken = `${URL_BASE}${encodeURIComponent(tokenFromUrl)}`;
    const response = await fetch(urlWithToken, {
      method: "GET",
      headers: {
        Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error en la solicitud HTTP");
    }

    const data = await response.json();
    if (data) {
      const { origen_zona: origenZona, estadoSincronizacion: valida_sincronizacion, ptoID } = data;
      
      if (valida_sincronizacion === "S") {
        Swal.fire({
          icon: "info",
          title: "¡Ups! Parece que ya ha registrado un pedido para esta sesión..",
          html: `<p>Por favor, consulta el status del pedido o finaliza el chat para generar uno nuevo.</p>
                 <button id="generate-menu-btn" class="swal2-confirm swal2-styled">Consultar pedido</button>`,
          showConfirmButton: false,
          allowOutsideClick: false,
          didRender: () => {
            document
              .getElementById("generate-menu-btn")
              .addEventListener("click", () => {
                window.location.href = REDIRECT_WHATSAPP;
              });
          },
        });

        Swal.fire({
          icon: "info",
          title: "Tu sesión ha caducado. Por favor, vuelve a ingresar al menú.",
          html: `<p>Por favor, genera uno nuevo.</p>
                 <button id="generate-menu-btn" class="swal2-confirm swal2-styled">Generar menú</button>`,
          showConfirmButton: false,
          allowOutsideClick: false,
          didRender: () => {
            document
              .getElementById("generate-menu-btn")
              .addEventListener("click", () => {
                window.location.href = REDIRECT_WHATSAPP;
              });
          },
        });

        window.location.href = REDIRECT_WHATSAPP;
        return response;
      }

      return { origenZona, ptoID };

      console.log("origenZona" + JSON.stringify(origenZona));
    } else {
      return { origenZona: null, ptoID: null };
    }
  } catch (error) {
    console.error("Error al procesar la solicitud:", error);
    return { origenZona: null, ptoID: null };
  }
};

const getItems = async (tokenFromUrl) => {

  const { origenZona, ptoID } = await getOrigenZona(tokenFromUrl);
  try {
    const response = await fetch(API_URL_ITEMS, {
      method: "GET",
      headers: {
        Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
        origenZona,
        ptoID,
      },
    });
    const data = await response.json();

    console.log("iTEMSSSS" + JSON.stringify(data));
    return data.items;
  } catch (error) {
    console.error("Error al obtener los items:", error);
    return null;
  }
};

const getCategories = async () => {
  try {
    const response = await fetch(API_URL_CATEGORIES, {
      method: "GET",
      headers: {
        Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
      },
    });
    const data = await response.json();

    console.log("Categorias" + JSON.stringify(data));

    return data.categories;
  } catch (error) {
    console.error("Error al obtener las categorias:", error);
    return null;
  }
};

const getModifierGroups = async () => {
  try {
    const response = await fetch(API_URL_MODIFIER_GROUPS, {
      method: "GET",
      headers: {
        Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
      },
    });
    const data = await response.json();

    console.log("MODIFICADORESSSS" + JSON.stringify(data));
    return data.modifier_groups;
  } catch (error) {
    console.error("Error al obtener los modificadores:", error);
    return null;
  }
};

export { getItems, getCategories, getModifierGroups };
