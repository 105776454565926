import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
 const [cart, setCart] = useState([]);

 const addToCart = (product, acompanante) => {
    setCart([...cart, product, acompanante ]);
 };

 const removeFromCart = (productId) => {
    setCart(cart.filter(item => item.app_item_id !== productId));
 };

 return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
 );
};
export default CartContext;